import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { useMediaQuery } from "react-responsive";
import Carousel from "../components/Carousel";
import CarouselResp from "../components/CarouselResp";
import { useRouter } from "next/router";
import { makeStyles } from "@material-ui/core";
import CardCampaign from "../components/CardCampaign";

function BannerSectionTwo(props) {
  const router = useRouter();
  const [isMobile, setIsMobile] = React.useState(false);
  const isMob = useMediaQuery({ maxWidth: 1024 });
  const xs = useMediaQuery({ maxWidth: 650 });
  const isLarge = useMediaQuery({ maxWidth: 1440 });
  const is4K = useMediaQuery({ minWidth: 2560 });

  React.useEffect(() => {
    if (isMob) {
      setIsMobile(isMob);
    } else {
      setIsMobile(false);
    }
  }, [isMob]);

  const data2 = [
    {
      icon: "/Icon/IconBannerTwo-1.svg",
      name: "Prosesnya Mudah",
      desc: "Donasi di infakonline.id prosesnya mudah dan tidak rumit, cukup pilih nominal donasi, metode pembayaran, masukkan nama dan no HP anda, lalu anda akan diarahkan untuk melakukan transaksi infak anda. In syaa Allah maksimal 5-10 menit saja, anda sudah berinvestasi untuk akhirat anda.",
    },
    {
      icon: "/Icon/IconBannerTwo-2.svg",
      name: "Berbagai Metode Pembayaran",
      desc: "Untuk memudahkan anda dalam berinfak ataupun membayar zakat,kami berupaya menghadirkan berbagai metode pembayaran. Mulai dari transfer ke rekening Bank hingga menggunakan dompet digital seperti LinkAja,Gopay,ShoopePay,DANA,dll.",
    },
    {
      icon: "/Icon/IconBannerTwo-3.svg",
      name: "Aman, Amanah, dan Transparan",
      desc: "Platform infakonline.id in syaa Allah sudah dibekali dengan keamanan yang sesuai standard, anda juga tidak perlu khawatir terkait penyaluran infak dan zakat anda. Karena infakonline.id dikelola oleh BM-ZIS Pontrenis yang terdaftar sebagai Unit Pengumpulan Zakat (UPZ) resmi dari BAZNAS. Dan anda juga dapat melihat setiap donasi yang masuk di platform infakonline.id juga tim infakonline.id akan melakukan update terkait penyaluran infak dan zakat yang telah anda donasikan sebagai bentuk transparansi.",
    },
    {
      icon: "/Icon/IconBannerTwo-4.svg",
      name: "Dukungan Teknis",
      desc: "Jika anda bingung atau memiliki kendala teknis, anda tidak perlu khawatir karena tim Support kami akan siap membantu anda. Cukup menghubungi kami via Whatsapp : 0812 7694 9644 atau melalui email : support@infakonline.id",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    // autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  return (
    <>
      {isMob ? (
        <>
          <>
            <Grid
              style={{
                margin: "30px 20px",
              }}
            >
              <Grid>
                <Typography
                  style={{ fontSize: 28, fontWeight: 700, color: "#4D4D4D" }}
                >
                  Infakonline
                </Typography>

                <Grid
                  style={{
                    // backgroundImage: "url(/Frame/Phone.svg)",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "right",
                  }}
                >
                  <Grid
                    style={{
                      paddingTop: 33,
                    }}
                  >
                    {data2.map((item) => (
                      <Grid style={{ display: "flex", marginBottom: 25 }}>
                        <Grid>
                          <img src={item.icon} style={{ width: 45 }} />
                        </Grid>
                        <Grid
                          style={{
                            marginTop: 8,
                            marginLeft: 16,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 20,
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 13,
                              fontWeight: 400,
                              margin: 0,
                              marginTop: 13,
                            }}
                          >
                            {item.desc}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ padding: xs ? "0px 16px" : "0px 32px" }}>
              {props?.campaigns?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 25,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: xs ? 16 : 28,
                      fontWeight: 600,
                      color: "#4d4d4d",
                    }}
                  >
                    Campaign Unggulan
                  </div>
                  <div
                    style={{
                      fontSize: xs ? 10 : 16,
                      fontWeight: 700,
                      color: "#28A96B",
                      cursor: "pointer",
                    }}
                    onClick={() => router.push("/campaigns")}
                  >
                    Lihat semua
                  </div>
                </div>
              )}

              <div style={{ margin: xs ? "0px 16px" : "0px 30px" }}>
                <Grid container spacing={3}>
                  {props?.campaigns?.slice(0, 6)?.map((data) => (
                    <Grid
                      onClick={() => props.handleClickCampaign()}
                      item
                      xs={xs ? 12 : 6}
                    >
                      <CardCampaign data={data} isBanner={true}/>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
            <Grid style={{ marginTop: 57 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 28,
                  marginBottom: 41,
                  color: "#4D4D4D",
                  marginLeft: 7,
                }}
              >
                Nasehat dan Berita
              </Typography>
              <Grid>
                {props.recentArticle?.length > 0 &&
                  props.recentArticle?.map((item) => (
                    <Grid style={{ margin: "0px 7px", marginBottom: 24 }}>
                      <Card onClick={() => router.push("/cms/" + item.slug)}>
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            // height="140"
                            image={item.image.url}
                          />
                          <CardContent>
                            <Grid style={{ display: "flex" }}>
                              <Grid
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid style={{ display: "flex" }}>
                                  <img src="/Icon/Calendar.svg" />
                                </Grid>
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginLeft: 9,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {new Date(item.createdAt).toLocaleDateString(
                                    "id-ID",
                                    {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: 30,
                                }}
                              >
                                <Grid style={{ display: "flex" }}>
                                  <img src="/Icon/Person.svg" />
                                </Grid>
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    marginLeft: 9,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {item.author}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography
                              style={{
                                fontSize: 22,
                                fontWeight: 600,
                                color: "#1A1A1A",
                              }}
                            >
                              {item.title}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                <Typography
                  onClick={() => {
                    router.push("/cms?category=");
                  }}
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "#28A96B",
                    textAlign: "center",
                    cursor: "pointer",
                    marginTop: 3,
                    marginBottom: 27,
                  }}
                >
                  Lihat Semua
                </Typography>
              </Grid>
            </Grid>

            <Grid
              style={{
                background: "rgba(94, 217, 158, 0.3)",
                padding: "20px 30px",
              }}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 24,
                  color: "#4D4D4D",
                }}
              >
                Partner kami
              </Typography>
              <Grid
                style={{
                  margin: "34px 0",
                  // paddingTop: 61,
                  justifyContent: "center",
                }}
              >
                <CarouselResp />
                {/* <Grid style={{ width: "37%" }} /> */}
              </Grid>
            </Grid>
          </>
        </>
      ) : (
        <>
          <>
            <Grid
              style={{
                margin: "33px 0px",
                marginLeft: 106,
              }}
            >
              <Grid>
                <Typography
                  style={{ fontSize: 28, fontWeight: 700, color: "#4D4D4D" }}
                >
                  Mengapa donasi di infakonline.id?
                </Typography>

                <Grid
                  style={{
                    backgroundImage: "url(/Frame/NewPhone.svg)",
                    // backgroundSize: "contain",
                    backgroundSize: is4K ? "35%" : isLarge ? "70%" : "45%",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "right",
                    paddingBottom: "10%",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingTop: 49,
                      width: "92%",
                    }}
                  >
                    <Grid style={{ width: "63%" }}>
                      <Grid style={{ display: "flex" }}>
                        <Grid itsm xs={6} style={{ display: "flex" }}>
                          <Grid>
                            <img src="/Icon/IconBannerTwo-1.svg" />
                          </Grid>
                          <Grid
                            style={{
                              alignItems: "center",
                              marginLeft: 19,
                              marginTop: 9,
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 20,
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Prosesnya Mudah
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                margin: 0,
                                marginTop: 15,
                              }}
                            >
                              Donasi di infakonline.id prosesnya mudah dan tidak
                              rumit, cukup pilih nominal donasi, metode
                              pembayaran, masukkan nama dan no HP anda, lalu
                              anda akan diarahkan untuk melakukan transaksi
                              infak anda. In syaa Allah maksimal 5-10 menit
                              saja, anda sudah berinvestasi untuk akhirat anda.
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          itsm
                          xs={6}
                          style={{ display: "flex", marginLeft: 45 }}
                        >
                          <Grid>
                            <img src="/Icon/IconBannerTwo-2.svg" />
                          </Grid>
                          <Grid
                            style={{
                              alignItems: "center",
                              marginLeft: 19,
                              marginTop: 9,
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 20,
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Berbagai Metode Pembayaran
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                margin: 0,
                                marginTop: 15,
                              }}
                            >
                              Untuk memudahkan anda dalam berinfak ataupun
                              membayar zakat,kami berupaya menghadirkan berbagai
                              metode pembayaran. Mulai dari transfer ke rekening
                              Bank hingga menggunakan dompet digital seperti
                              LinkAja,Gopay,ShoopePay,DANA,dll.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid style={{ display: "flex", marginTop: 70 }}>
                        <Grid itsm xs={6} style={{ display: "flex" }}>
                          <Grid>
                            <img src="/Icon/IconBannerTwo-3.svg" />
                          </Grid>
                          <Grid
                            style={{
                              alignItems: "center",
                              marginLeft: 19,
                              marginTop: 9,
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 20,
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Aman, Amanah, dan Transparan
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                margin: 0,
                                marginTop: 15,
                              }}
                            >
                              Platform infakonline.id in syaa Allah sudah
                              dibekali dengan keamanan yang sesuai standard,
                              anda juga tidak perlu khawatir terkait penyaluran
                              infak dan zakat anda. Karena infakonline.id
                              dikelola oleh BM-ZIS Pontrenis yang terdaftar
                              sebagai Unit Pengumpulan Zakat (UPZ) resmi dari
                              BAZNAS. Dan anda juga dapat melihat setiap donasi
                              yang masuk di platform infakonline.id juga tim
                              infakonline.id akan melakukan update terkait
                              penyaluran infak dan zakat yang telah anda
                              donasikan sebagai bentuk transparansi.
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          itsm
                          xs={6}
                          style={{ display: "flex", marginLeft: 45 }}
                        >
                          <Grid>
                            <img src="/Icon/IconBannerTwo-4.svg" />
                          </Grid>
                          <Grid
                            style={{
                              alignItems: "center",
                              marginLeft: 19,
                              marginTop: 9,
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: 20,
                                fontWeight: 600,
                                margin: 0,
                              }}
                            >
                              Dukungan Teknis
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                margin: 0,
                                marginTop: 15,
                              }}
                            >
                              Jika anda bingung atau memiliki kendala teknis,
                              anda tidak perlu khawatir karena tim Support kami
                              akan siap membantu anda. Cukup menghubungi kami
                              via Whatsapp : 0812 7694 9644 atau melalui email :
                              support@infakonline.id
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid style={{ width: "37%" }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ margin: "0px 100px" }}>
              {props?.campaigns?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 25,
                  }}
                >
                  <div
                    style={{ fontSize: 28, fontWeight: 600, color: "#4d4d4d" }}
                  >
                    Campaign Unggulan
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 700,
                      color: "#28A96B",
                      cursor: "pointer",
                    }}
                    onClick={() => router.push("/campaigns")}
                  >
                    Lihat semua
                  </div>
                </div>
              )}

              <div style={{ margin: "0px 30px" }}>
                <Grid container spacing={3}>
                  {props?.campaigns?.slice(0, 6)?.map((data) => (
                    <Grid
                      onClick={() => props.handleClickCampaign()}
                      item
                      xs={4}
                    >
                      <CardCampaign data={data} isBanner={true}/>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
            <Grid style={{ margin: "33px 99px" }}>
              <Grid>
                <Typography
                  style={{
                    fontWeight: 700,
                    fontSize: 28,
                    marginBottom: 41,
                    color: "#4D4D4D",
                    marginLeft: 7,
                  }}
                >
                  Nasehat dan Berita
                </Typography>
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {props.recentArticle?.length > 0 &&
                    props.recentArticle?.map((item) => (
                      <Grid item xs={4} style={{ margin: "0px 7px" }}>
                        <Card onClick={() => router.push("/cms/" + item.slug)}>
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              // height="140"
                              image={item.image.url}
                            />
                            <CardContent>
                              <Grid style={{ display: "flex" }}>
                                <Grid
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Grid style={{ display: "flex" }}>
                                    <img src="/Icon/Calendar.svg" />
                                  </Grid>
                                  <Typography
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      marginLeft: 9,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {new Date(
                                      item.createdAt
                                    ).toLocaleDateString("id-ID", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}
                                  </Typography>
                                </Grid>
                                <Grid
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: 30,
                                  }}
                                >
                                  <Grid style={{ display: "flex" }}>
                                    <img src="/Icon/Person.svg" />
                                  </Grid>
                                  <Typography
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 400,
                                      marginLeft: 9,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item.author}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography
                                style={{
                                  fontSize: 22,
                                  fontWeight: 600,
                                  color: "#1A1A1A",
                                }}
                              >
                                {item.title}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>

            <Grid
              style={{
                background: "rgba(94, 217, 158, 0.3)",
                paddingTop: 33,
                marginTop: 74,
              }}
            >
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 28,
                  color: "#4D4D4D",
                  padding: "0px 106px",
                }}
              >
                Partner kami
              </Typography>
              <Carousel />
            </Grid>
          </>
        </>
      )}
    </>
  );
}

export default BannerSectionTwo;
