import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowForwardIosRoundedIcon
      className={className}
      style={{ ...style, display: "block", color: "#CCCCCC", fontSize: 40 }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIosRoundedIcon
      className={className}
      style={{ ...style, display: "block", color: "#CCCCCC", fontSize: 40 }}
      onClick={onClick}
    />
  );
}

export default function Carousel() {
  const settings = {
    dots: false,
    infinite: true,
    className: "center",
    autoplay: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      {/* <div style={{ padding: "30px 0px", margin: "0px 105px" }}> */}
      <Slider {...settings}>
        <div>
          <img
            src="/Frame/banksyariah.jpeg"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/midtrans.png"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/xendit.jpeg"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/linkaja.jpeg"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/gopay.png"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/shopeepay.jpeg"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/ovo.jpeg"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/dana.png"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <img
            src="/Frame/Baznas.png"
            style={{
              margin: "0 auto",
              borderRadius: 100,
              width: 98,
              height: 98,
            }}
          />
        </div>
      </Slider>
      {/* </div> */}
    </div>
  );
}
